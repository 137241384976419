"use client"
import { DateTime } from "luxon"
import { InputGetThroughputByProduct, ProductThroughputDetail, TimeFrame, TimeZone } from "types"
import { TimeMapping, getBeginingOfDayTimestamp, getEndOfDayTimestamp } from "utilities"
import { useGetThroughputByProductQuery } from "../../../api"
import { getAPriorDate } from "../../../utilities/getAPriorDate"
import { usePPSub } from "../../ppSubscription"

export interface IuseThroughputByProductAndDate {
    throughputByProductHookData?: ProductThroughputDetail[]
    isFetchingThroughputByProduct: boolean
    refetchThroughputByProduct: () => void
    isLoadingThroughputByProduct: boolean
    isErrorThroughputByProduct: boolean
}

/**
 * Custom hook to fetch throughput data by product and date range.
 *
 * @param dateRange - Optional array of two Date objects representing the start and end dates.
 * @returns An object containing throughput data and various states of the data fetching process.
 *
 * @property throughputByProductHookData - The fetched throughput data.
 * @property isFetchingThroughputByProduct - Boolean indicating if the data is currently being fetched.
 * @property refetchThroughputByProduct - Function to refetch the throughput data.
 * @property isLoadingThroughputByProduct - Boolean indicating if the data is in the loading state.
 * @property isErrorThroughputByProduct - Boolean indicating if there was an error fetching the data.
 */
export const useThroughputByProductAndDate = (
    dateRange?: Date[]
): IuseThroughputByProductAndDate => {
    const { ppSubId, ppSubApiData } = usePPSub()
    const userTimeZone =
        TimeMapping[ppSubApiData?.settings?.timeZone] || TimeMapping["US___East__Indiana"]
    let startDate
    let endDate

    if (dateRange && dateRange[0] && dateRange[1]) {
        startDate = getBeginingOfDayTimestamp(
            DateTime.fromJSDate(dateRange[0]).setZone(userTimeZone).toString()
        )
        endDate = getEndOfDayTimestamp(
            DateTime.fromJSDate(dateRange[1]).setZone(userTimeZone).toString()
        )
    } else {
        const yesterday = getAPriorDate({
            number: 1,
            timezone: ppSubApiData?.settings?.timeZone
        }).toString()
        startDate = getBeginingOfDayTimestamp(yesterday)
        endDate = getEndOfDayTimestamp(yesterday)
    }

    const inputData: InputGetThroughputByProduct = {
        ppSubId,
        dateRange: {
            startDate,
            endDate
        },
        sortAscending: false,
        timeFrame: TimeFrame.Day,
        timeZone: ppSubApiData?.settings?.timeZone || TimeZone.UsEastern
    }

    const {
        data: throughputByProductHookData,
        isFetching: isFetchingThroughputByProduct,
        isLoading: isLoadingThroughputByProduct,
        isError: isErrorThroughputByProduct,
        refetch: refetchThroughputByProduct
    } = useGetThroughputByProductQuery(inputData, {
        skip: dateRange && (!dateRange[0] || !dateRange[1])
    })

    return {
        throughputByProductHookData,
        isFetchingThroughputByProduct,
        refetchThroughputByProduct,
        isLoadingThroughputByProduct,
        isErrorThroughputByProduct
    }
}
